import React, { useState, useEffect, Suspense } from "react";
import { useAuth } from "../../../provider";
import * as api from "../../../services/services";
import Loading from "../../../components/Loading/Loading";
import ReportTabs from "./ReportTabs/ReportTabs";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import "./reports.scss";
import ButtonMain from "../../../components/ButtonMain/ButtonMain";
import ModalBox from "../../../components/ModalBox/ModalBox";
import Notes from "./Notes/Notes";
import Medication from "./Medication/Medication";
import MedicalReports from "./MedicalReports/MedicalReports";
import { downloadFile, base64ToBlob } from "../../../helpers/helpers";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";

const Reports = () => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////

	/**
	 * @description Carga de componentes lazy (bajo demanda)
	 */

	const childrenComp = [
		{
			label: "Informes médicos",
			url: "/Paciente/Informes/InfoMedica",
		},
		{ label: "Recetas", url: "/Paciente/Informes/Medicacion" },
		{ label: "Justificantes", url: "/Paciente/Informes/Justificantes" },
	];

	const [error, setError] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [errorStatus, setErrorStatus] = useState(null);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalErrorOpen, setModalErrorOpen] = useState(false);
	const [modalSuccessOpen, setModalSuccessOpen] = useState(null);
	const [loading, setLoading] = useState(false);
	const [notesLoading, setNotesLoading] = useState(true);
	const { state, setReports, setNotes, setUserReportTypes, setGlobalLoading } = useAuth();
	const [reportTypes, setReportTypes] = useState([]);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [activeTab, setActiveTab] = useState(childrenComp[0]);
	const [base64String, setBase64String] = useState(null)
	/**
	 * @description Cuando se renderiza el componente se cargan los informes y se filtran según su tipo
	 */

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getDataAsync();
		}
		return () => {
			mounted = false;
		};
	}, [activeTab]);

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	async function getDataAsync() {
		setLoading(true);
		setError(null);
		setErrorMessage(null);
		setErrorStatus(null);

		try {
			setIsRefreshing(true);

			// Llamada para conseguir el tipo de informes

			let responseTypes = await api.getReportTypes(state.user);
			setReportTypes(responseTypes);

			setUserReportTypes(responseTypes);

			// Llamada para conseguir los informes médicos

			if (activeTab.label === "Informes médicos") {
				setLoading(true);

				if (state.userReports) {
					setLoading(false);
					setIsRefreshing(true);
				}
				let reports = await api.getReports(state.user);
				setReports(reports);
				setLoading(reports ? false : true);
				setIsRefreshing(reports ? false : true);
			}

			// Llamada para conseguir la medicación. Esta funcionalidad no está implementada de momento

			if (activeTab.label === "Medicación") {
				//   if (state.userMedication) {
				setLoading(false);
				setIsRefreshing(false);
				//   }
				//   let medication = await api.getMedications(state.user);
				//   setMedication(medication);
				//   setLoading(medication ? false : true);
			}

			// Llamada para conseguir los justificantes

			if (activeTab.label === "Justificantes") {
				setNotesLoading(true);
				if (state.userNotes) {
					setNotesLoading(false);
					setIsRefreshing(true);
				}
				let notes = await api.getNotes(state.user);

				setNotes(notes);
				setNotesLoading(notes ? false : true);
				setIsRefreshing(notes ? false : true);
			}
		} catch (error) {
			setLoading(false);
			setNotesLoading(false);
			setIsRefreshing(false);
			setErrorMessage(error.message);
			setErrorStatus(error.status);
		}
	}

	/**
	 *
	 * @param {Object} currentDoc
	 * @description Gestión de la petición de descarga de un documento.
	 * En la función se filtra el documento pasado por parámetros
	 * y se ejecuta una función u otra dependiendo del tipo del mismo.
	 *
	 */

	const getDocPdf = async (currentDoc, isDownload) => {
		setError(null);
		try {
			console.log(currentDoc, isDownload)
			setGlobalLoading(true);
			setTimeout(async () => {
				if (currentDoc.idInformeMedico) {
					let response = await api.getReportPdf(state.user, currentDoc.idInformeMedico);
					if (response === "") {
						setGlobalLoading(false);
						setErrorMessage("HA HABIDO UN ERROR");
						return;
					}
					const fileName = `Informe médico ${currentDoc.idInformeMedico}`;

					if (isDownload) {
						await downloadFile(response, fileName, "pdf");

					} /* else { // Se elimina la funcionalidad de view pdf
						console.log("Entra")
						//base64ToBlob(response)
						setBase64String({ base64str: response, fileName })
					} */

					setGlobalLoading(false);
				} else if (currentDoc.idJustificante) {
					setGlobalLoading(true);

					let response = await api.getReportPdf(state.user, currentDoc.idJustificante);
					if (response === "") {
						setGlobalLoading(false);
						setErrorMessage("HA HABIDO UN ERROR");
						return;
					}
					const fileName = `Justificante ${currentDoc.idJustificante}`;

					if (isDownload) {
						await downloadFile(response, fileName, "pdf");

					} else {
						console.log("Entra")
						setBase64String({ base64str: response, fileName })
						//base64ToBlob(response)
					}

					setGlobalLoading(false);
				}
			}, 10000);
		} catch (error) {
			setErrorMessage(error.message);
			setErrorStatus(error.status);
		}
	};

	/**
	 *
	 * @param {object} values
	 * @description Petición al servidor para generar un nuevo informe médico o
	 * en su defecto gestione los errores del servidor
	 */

	async function onRequestReport(values) {
		try {
			setLoading(true);
			let response = await api.requestReport(values);
			setModalOpen(false);
			setModalSuccessOpen(response);
		} catch (error) {
			setModalOpen(false);
			setErrorMessage(error.message);
		}
		setLoading(false);
	}

	/**
	 *
	 * @param {object} values
	 * @description Petición al servidor para generar un nuevo justificante o
	 * en su defecto gestione los errores del servidor
	 */

	async function onRequestNote(values) {
		try {
			setNotesLoading(true);
			let response = await api.requestReport(values);
			setModalOpen(false);
			setModalSuccessOpen(response);
		} catch (error) {
			setModalOpen(false);
			setErrorMessage(error.message);
		}
		setNotesLoading(false);
	}

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	/**
	 *
	 * @description Renderizado y enrutado de las tabs. Cada vez que se pulsa
	 * en una tab se activa una ruta que carga un componente lazy como contenido de la tab.
	 */

	const onClickTabItem = (tabLabel) => {
		const activatedTab = childrenComp.filter((tab) => tab.label === tabLabel);
		setActiveTab(activatedTab[0]);
	};

	/**
	 * @description Gestión del modal de éxito en la petición de generar informe
	 */

	const renderSuccessModal = () => {
		const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setModalSuccessOpen}>
					<p className="success-info-message">
						{modalSuccessOpen}
					</p>
					<ButtonMain action={setModalSuccessOpen} label="OK" actionParams={false} />
				</LazyModal>


			</Suspense>
		);
	};

	/**
	 * @description Gestión del modal de error en la petición de generar informe
	 */

	const renderErrorModal = (error) => {
		const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setModalErrorOpen}>
					<ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} />
					<ButtonMain action={setModalErrorOpen} label="OK" actionParams={false} />
				</LazyModal>
			</Suspense>
		);
	};

	/**
	 * @description Renderizado del componente de carga de documentos
	 */

	const renderGlobalLoading = () => {
		return (
			<ModalBox hasCloseButton={error ? false : true} onToggle={setGlobalLoading}>
				{error ? (
					<ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} />
				) : (
					<React.Fragment>
						<Loading />
					</React.Fragment>
				)}
			</ModalBox>
		);
	};

	/**
	 * @description Renderizado del componente. Se crea una constante con
	 * las props que recibirán ReportTabs, MedicalReportsLazy y NotesLazy.
	 */

	const propsLoading = activeTab.label === "Justificantes" ? notesLoading : loading;

	const reportTabsProps = {
		childrenComp,
		activeTab,
		setActiveTab,
		onClickTabItem,
		isRefreshing,
		setError,
		loading: propsLoading,
	};
	const medicalReportsProps = {
		data: state.userReports,
		dataUser: state.user,
		reportTypes,
		onRequestReport,
		getReportPdf: getDocPdf,
		error,
		modalSuccessOpen,
		setModalSuccessOpen,
		modalOpen,
		setModalOpen,
		isRefreshing,
		loading,
		errorMessage,
	};
	const notesProps = {
		data: state.userNotes,
		getReportPdf: getDocPdf,
		onSubmitAction: onRequestNote,
		modalOpen,
		setModalOpen,
		isRefreshing,
		error,
		reportTypes,
		loading: notesLoading,
	};
	return (
		<React.Fragment>
			<BrowserRouter>
				{modalSuccessOpen ? renderSuccessModal() : null}
				{modalErrorOpen ? renderErrorModal(error) : null}
				{state.globalLoading ? renderGlobalLoading() : null}
				
				<ReportTabs {...reportTabsProps}>
					{/* Cuando se cargue el componente Reports nos redirigirá a la dirección 
         			/Pacientes/Informes/InfoMedica, es decir, la dirección de la tab que inicializa por defecto */}

					{errorMessage ? (
						<ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} />
					) : null}
					<Route path="/Paciente/Informes">
						<Redirect to="/Paciente/Informes/InfoMedica"></Redirect>
					</Route>

					<Route exact path="/Paciente/Informes/InfoMedica">
						<MedicalReports {...medicalReportsProps} />
					</Route>

					<Route exact path="/Paciente/Informes/Medicacion">
						<Medication />
					</Route>
					<Route exact path="/Paciente/Informes/Justificantes">
						<Notes {...notesProps} />
					</Route>
				</ReportTabs>
			</BrowserRouter>

		</React.Fragment>
	);
};

export default Reports;
