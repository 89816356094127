import React, { useState, useEffect, Suspense } from "react";
import { useAuth } from "../../../../provider";
import * as api from "../../../../services/services";
import moment from "moment";
import ModalBox from "../../../../components/ModalBox/ModalBox";
import ButtonMain from "../../../../components/ButtonMain/ButtonMain";
import Loading from "../../../../components/Loading/Loading";
import userIcon from "../../../../assets/images/icons/user-b.svg";
import emailIcon from "../../../../assets/images/icons/email-b.svg";
import phoneIcon from "../../../../assets/images/icons/phone-b.svg";
import calendarIcon from "../../../../assets/images/icons/calendar-small-b.svg";
import cardIcon from "../../../../assets/images/icons/card-b.svg";

import "./personalData.scss";
import ButtonBack from "../../../../components/ButtonBack/ButtonBack";
import ErrorHandler from "../../../../components/ErrorHandler/ErrorHandler";
import Updating from "../../../../components/Updating/Updating";
import { useLocation } from "react-router";

/**
 * @description Renderizado de la vista de datos personales.
 */


const PersonalData = () => {
  /////////////////////////////
  // Configuración del componente
  /////////////////////////

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [accent, setAccent] = useState(null)

  const { state, setUserData } = useAuth();

  const listItems = [
    {
      key: "dd1",
      label: "Nombre y apellidos",
      text: `${state.userData.nombre} ${state.userData.primerApellido} ${state.userData.segundoApellido}`,
      editable: false,
      icon: userIcon,
    },
    {
      key: "dd2",
      label: "NIF",
      text: state.userData.dni,
      editable: false,
      icon: cardIcon,
    },
    {
      key: "dd3",
      label: "Fecha de nacimiento",
      text: moment(state.userData.fechaNacimiento).format("DD-MM-YYYY"),
      editable: false,
      icon: calendarIcon,
    },
    {
      key: "dd4",
      label: "Correo electrónico",
      text: state.userData.email,
      editable: true,
      icon: emailIcon,
    },
    {
      key: "dd5",
      label: "Teléfono",
      text: state.userData.telefono,
      editable: true,
      icon: phoneIcon,
    },
  ];

  const query = useQuery()
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}

  useEffect(() => {
    getDataAsync().then(()=>{
      setAccent(query.get('edit'))
      if(query.get('edit')){
        setModalOpen(true)
      } 
    });
  }, []);

  /////////////////////////////
  // Gestión de eventos y servicios
  /////////////////////////////

  const toggleModal = () => setModalOpen(!modalOpen);

  /**
   *
   * @param {boolean} hasToUpdate
   * @description Petición al servidor para recuperar los datos del usuario
   */

  async function getDataAsync(hasToUpdate = false) {
    if (!state.userData || hasToUpdate) {
      try {
        await api.getUserData(state.user);
      } catch (error) {
        setErrorStatus(error.status);
        setErrorMessage(error.message);
      }
    }
  }
  /**
   *
   * @param {Object} values
   * @description Llamada al servidor que actualiza los datos de usuario.
   */

  async function onSaveDetails(values) {
    console.log('onSaveDetails')
    setModalOpen(false);
    setIsRefreshing(true)
    setIsSubmitted(true)
    try {
      await api.updateUser(values);
      const setUserRes = await api.getUserData(state.user);
      setUserData(setUserRes);
      setIsRefreshing(false)

    } catch (error) {
      setErrorStatus(error.status);
      setErrorMessage(error.message);
      setIsRefreshing(false)

    }
  }

  /////////////////////////////
  // Funciones de renderizado
  /////////////////////////////

  /**
   * @description Renderiza el modal en el que se podrán cambiar los datos del usuario
   */

  const renderModal = () => {
    const LazyAccountReview = React.lazy(() => import("../AccountReview/AccountReview"));

    const props = {
      data: state.userData,
      accent: accent,
      onSubmitAction: onSaveDetails,
      setErrorMessage,
      setErrorStatus,
      errorMessage,
      errorStatus,
    };
    return modalOpen ? (
      <Suspense
        fallback={
          <ModalBox>
            <Loading />
          </ModalBox>
        }
      >
        <ModalBox onToggle={setModalOpen}>
          <LazyAccountReview {...props} />
        </ModalBox>
      </Suspense>
    ) : null;
  };

  /**
   * @description renderizado de errores
   */

  const renderErrors = () =>
    errorMessage || errorStatus ? <ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} /> : null;

  return (
    <div className="personal-data">
      {renderModal()}
      {isSubmitted ? <Updating isRefreshing={isRefreshing}/> : null}
      {renderErrors()}
      <div className="content-top">
        <ButtonBack />
        <ButtonMain action={toggleModal} customClass="primary" iconClass="edit" />
      </div>
      <div className="columns-container">
        <div className="left-column">
          <ul>
            {listItems
              .filter((item) => !item.editable)
              .map((item, index) => {
                return (
                  <li key={index}>
                    <div className="text">
                      <p className="label">{item.label}</p>
                      <p className="readonly">
                        <span>{item.text || ""}</span>
                        <img className="input-icon" src={item.icon} alt="" />
                      </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="right-column">
          <ul>
            {listItems
              .filter((item) => item.editable)
              .map((item, index) => {
                return (
                  <li key={index}>
                    <div className="text">
                      <p className="label">{item.label}</p>
                      <p className="readonly">
                        <span>{item.text || ""}</span>
                        <img className="input-icon" src={item.icon} alt="" />
                      </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PersonalData;
