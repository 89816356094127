import React from "react";
import logo from "../../assets/images/lg-baviera-color.svg";
import LinkButton from "../LinkButton/LinkButton";
import Updating from "../Updating/Updating";
import "./header.scss";

/**
 *
 * @param {string} props.userName
 * @param {function} props.onLogout
 * @param {boolean} props.loading
 *
 * @description Renderizado de la información básica del usuario (nombre)
 * y renderizado del código que albergara la función de logout.
 *
 */

const Header = (props) => {
	const { userName, onLogout, loading } = props;

	const getViewPortWidth = () => window.innerWidth;
	const viewportWidth = getViewPortWidth();

	return (
		<header className="main-header app-header">
			{viewportWidth <= 540 ? (
				<div className="logo-mobile">
					<img src={logo} alt="Clínica Baviera" />
				</div>
			) : (
				""
			)}

			<div className="header-actions">
				<div className="logout" onClick={onLogout}>
					Cerrar sesión
				</div>
				<LinkButton url={"/Paciente/SubirDocumento"} urlLabel={"Subir documentos"} type = {"upload-file-button"}/>
			</div>
			<div className="hello-text">
				{loading ? (
					<Updating isRefreshing={loading} />
				) : (
					<React.Fragment>
						<p className="greeting">Hola</p>
						<p className="user-name">{userName}</p>
					</React.Fragment>
				)}
			</div>
		</header>
	);
};



export default Header;
