import React, { Suspense, useEffect, useState } from "react";
import ButtonMain from "../../../components/ButtonMain/ButtonMain";
import Card from "../../../components/Card/Card";
import Loading from "../../../components/Loading/Loading";
import ModalBox from "../../../components/ModalBox/ModalBox";
import MakeCall from "./MakeCall/MakeCall";
import MakeConference from "./MakeConference/MakeConference";
import iconDoctor from "../../../assets/images/icons/doctor-color-icon.svg";
import iconCall from "../../../assets/images/icons/call-color-icon.svg";
import { useAuth } from "../../../provider";
import * as api from "../../../services/services";
import "./contact.scss";
import ErrorDialog from "../../../components/ErrorDialog/ErrorDialog";

/**
 * @description Éste es el componente que actúa como contenedor de todos los componentes en la ruta /Paciente/Contacto
 */

const Contact = () => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////

	const [callModal, setCallModal] = useState(false);
	const [conferenceModal, setConferenceModal] = useState(false);
	const { state } = useAuth();
	const [reasons, setReasons] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [errorStatus, setErrorStatus] = useState(null);

	//Conference

	const [dataDates, setDataDates] = useState([]);
	const [dataTimes, setDataTimes] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [reason, setReason] = useState(null);
	const [modalSuccessOpen, setModalSuccessOpen] = React.useState(false);

	const options = [
		{
			key: "C1",
			title: "Tu médico te llama",
			onClick: () => {
				setErrorMessage(null);
				setErrorStatus(null);
				setCallModal(true);
			},
			icon: iconDoctor,
		},
		{
			key: "C2",
			title: "Solicita tu videoconsulta médica",
			onClick: () => setConferenceModal(true),
			icon: iconCall,
		},
	];

	useEffect(() => {
		getDataAsyncCall();
		return () => {}
	}, []);

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 * @description Hace una llamada a la API para conseguir la lista de motivos de llamada
	 *
	 */

	async function getDataAsyncCall() {
		try {
			setLoading(true);
			let response = await api.getContactReasons(state.user);

			var result = [];
			response.forEach((item) =>
				result.push({
					label: item.nombre,
					value: item.idMotivoContacto,
					key: item.idMotivoContacto,
				})
			);
			setReasons(result);
			setLoading(response ? false : true);
		} catch (error) {
			setLoading(false);
			setError(error.message);
		}
	}

	/**
	 * @param {Object} values
	 * @description Gestiona el post a la API en el que se solicita una llamada o un contacto.
	 *
	 *
	 */

	const handleCallSubmit = async (values) => {
		try {
			setLoading(true);
			var response = await api.requestContact(values);
			if (response === 0 || response === "0") {
				setCallModal(false);
				setModalSuccessOpen(true);
			}

			setLoading(response ? false : true);
		} catch (error) {
			setLoading(false);
			setErrorStatus(error.status);
			setErrorMessage(error.message);
		}
	};

	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	/**
	 * @description Renderiza el modal de solicitar llamada
	 */

	const renderCallModal = () => {
		const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		const props = { loading, reasons, setReasons, error, setError, handleCallSubmit, errorMessage, errorStatus };
		return (
			<Suspense
				fallback={
					<ModalBox onToggle={setCallModal}>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setCallModal}>
					<MakeCall {...props} />
				</LazyModal>
			</Suspense>
		);
	};

	/**
	 * @description Renderiza el modal de solicitar Videoconsulta
	 */

	const renderConferenceModal = () => {
		const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		const props = {
			reason,
			dataDates,
			selectedDate,
			dataTimes,
			loading,
			setReason,
			setSelectedDate,
			setDataDates,
			setDataTimes,
			errorMessage,
			errorStatus,
		};

		return (
			<Suspense
				fallback={
					<ModalBox>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setConferenceModal}>
					<MakeConference {...props} />
				</LazyModal>
			</Suspense>
		);
	};

	/**
	 * @description Renderiza el modal de éxito en la solicitud
	 *
	 */

	const renderSuccessModal = () => {
		const LazyModal = React.lazy(() => import("../../../components/ModalBox/ModalBox"));

		return (
			<Suspense
				fallback={
					<ModalBox onToggle={setModalSuccessOpen}>
						<Loading />
					</ModalBox>
				}
			>
				<LazyModal onToggle={setModalSuccessOpen}>
					<div className="success-text">Tu petición se ha realizado con éxito</div>
				</LazyModal>
			</Suspense>
		);
	};

	/**
	 * @description Renderiza errores provenientes del servidor
	 */

	const renderErrors = () => (error ? <ErrorDialog text={error} /> : null);

	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	useEffect(() => {
		if (callModal) {
			document.querySelector("html").style.overflow = "hidden";
			document.querySelector("html").classList.add("no-overflow");
		} else {
			document.querySelector("html").style.overflow = "auto";
			document.querySelector("html").classList.remove("no-overflow");
		}
	}, [callModal]);

	const overflow = callModal ? "no-overflow" : null;

	return (
		<React.Fragment>
			<div className={`title-container`}>
				<h2 className="subtitle">¿Tienes dudas?</h2>
				<p>
					Programa una videoconsulta con un doctor o si lo prefieres tu médico te llama para resolver todas
					tus dudas.
				</p>
			</div>
			{renderErrors()}
			<div className="contact-container">
				{options.map((option) => {
					return (
						<React.Fragment key={option.key}>
							<Card>
								<div className="icon-image">
									<img src={option.icon} alt="" />
								</div>
								<h3>{option.title}</h3>
								<ButtonMain label="Continuar" action={option.onClick} iconClass="arrow-right-white" />
							</Card>
						</React.Fragment>
					);
				})}
				{callModal ? renderCallModal() : null}
				{conferenceModal ? renderConferenceModal() : null}
				{modalSuccessOpen ? renderSuccessModal() : null}
			</div>
		</React.Fragment>
	);
};

export default Contact;
