import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../provider";
import * as api from "../../../../services/services";
import Loading from "../../../../components/Loading/Loading";
// import moment from "moment";
import budgetIcon from "../../../../assets/images/icons/budget-color-icon.svg";
import invoiceIcon from "../../../../assets/images/icons/invoice-color-icon.svg";
import noFilesBackground from "../../../../assets/images/no-files.jpg";
import ReportContent from "../../../../components/ReportContent/ReportContent";
import "./invoices.scss";
import ButtonBack from "../../../../components/ButtonBack/ButtonBack";
import ModalBox from "../../../../components/ModalBox/ModalBox";
import ErrorDialog from "../../../../components/ErrorDialog/ErrorDialog";
import Updating from "../../../../components/Updating/Updating";
import ErrorHandler from "../../../../components/ErrorHandler/ErrorHandler";
import { base64ToBlob, downloadFile } from "../../../../helpers/helpers";

/**
 * @description Renderizado de la vista de Facturas y presupuestos.
 *
 */

const Invoices = () => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////

	const [errorMessage, setErrorMessage] = useState(null);
	const [errorStatus, setErrorStatus] = useState(null);
	const [loading, setLoading] = useState(false);
	const { state, setInvoices, setGlobalLoading } = useAuth();
	const [isRefreshing, setIsRefreshing] = useState(false);

	useEffect(() => {
		getDataAsync();
	}, []);

	const hasElements = () => state.userInvoices && state.userInvoices.length > 0;

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	async function getDataAsync() {
		fetchInvoices(state.userInvoices);
	}

	/**
	 *
	 * @param {array} stateInvoices
	 * @see getDataAsync
	 * @description Si hay invoices en el estado global, son estos los
	 * que se renderizan hasta que se complete una llamada a la api.
	 *
	 */

	const fetchInvoices = async (stateInvoices) => {
		setErrorStatus(null);
		setErrorMessage(null);
		try {
			setIsRefreshing(true);
			if (stateInvoices && stateInvoices.length > 0) {
				setLoading(false);
			} else {
				setLoading(true);
			}

			let response2 = await api.getInvoices(state.user);
			let response1 = await api.getBudgets(state.user);

			const responses = response2.concat(response1);

			setInvoices(responses);
			setIsRefreshing(false);
			setLoading(stateInvoices || responses ? false : true);
		} catch (error) {
			setLoading(false);
			setIsRefreshing(false);
			setErrorMessage(error.message);
			setErrorStatus(error.status);
		}
	};

	/**
	 *
	 * @param {Object} currentDoc
	 * @description Hace una llamada a la api que recupera un pdf en base64.
	 * Se lanza una función u otra de la api dependiendo del tipo de documento
	 * En esta función se llama a {downloadFile} un
	 * helper que convierte el formato base64 en pdf.
	 */

	const onPdfLinkClick = async (currentDoc, isDownload) => {
		setErrorMessage(null);
		setErrorStatus(null);

		try {
			setGlobalLoading(true);
			setTimeout(async () => {
				if (currentDoc.idPresupuesto) {
					let response = await api.getBudgetPdf(state.user, currentDoc.idPresupuesto);
					if (response == "") {
						setGlobalLoading(false);
						setErrorMessage("HA HABIDO UN ERROR");
					}
					const docName = `Presupuesto.${currentDoc.idPresupuesto}.pdf`;
					if (isDownload) {
						downloadFile(response, docName, "pdf");
					} else {
						base64ToBlob(response);
					}
					setGlobalLoading(false);
				} else {
					let response = await api.getInvoicePdf(
						state.user,
						currentDoc.numeroFactura,
						currentDoc.serie
					);
					if (response == "") {
						setGlobalLoading(false);
						setErrorMessage("HA HABIDO UN ERROR");
					}
					const docName = `Factura nº${currentDoc.numeroFactura}, nº de serie ${currentDoc.serie}.pdf`;
					if (isDownload) {
						downloadFile(response, docName, "pdf");
					} else {
						base64ToBlob(response);
					}
					setGlobalLoading(false);
				}
			}, 10000);
		} catch (error) {
			setGlobalLoading(false);
			setErrorStatus(error.status);
			setErrorMessage(error.message);
		}
	};

	/**
	 * @description Una vez que se hace una petición a la api para que recupere el pdf
	 * se levanta, mediante una función de redux, un modal de carga
	 * Si globalLoading está seteado a true, se levantará esta función
	 */

	const renderGlobalLoading = () => {
		return (
			<ModalBox
				hasCloseButton={errorMessage || errorStatus ? false : true}
				onToggle={setGlobalLoading}
			>
				{errorMessage || errorStatus ? (
					<ErrorDialog text={errorMessage || errorStatus} />
				) : (
					<React.Fragment>
						{renderErrors()}
						<Loading />
					</React.Fragment>
				)}
			</ModalBox>
		);
	};

	/**
	 * @description Renderizado en caso de que no existan invoices
	 */

	const renderEmpty = () => {
		if (!loading && !state.stateInvoices) {
			return (
				<React.Fragment>
					{/* {renderErrors()} */}
					<div className="empty-container">
						<img src={noFilesBackground} alt="" />
						<p className="no-content-info">
							En estos momentos no tienes facturas ni presupuestos disponibles
						</p>
					</div>
				</React.Fragment>
			);
		} else {
			return null;
		}
	};

	/**
	 * @description Renderizado en caso de que existan documentos
	 * Los documentos se fultran en esta función,
	 * para que puedan ser renderizados en columnas diferentes
	 */

	const renderElements = () => {
		const invoices = state.userInvoices.filter((invoice) => invoice.numeroFactura);
		const budgets = state.userInvoices.filter((invoice) => !invoice.numeroFactura);

		return (
			<React.Fragment>
				{state.globalLoading ? renderGlobalLoading() : null}

				{/* Columna de presupuestos  */}

				{!loading && (
					<div className="columns-container invoices">
						<div className="left-column">
							<div className="column-top">
								<img src={invoiceIcon} alt="icono facturas" />
								<h3>Presupuestos</h3>
							</div>
							{budgets.length > 0 ? (
								<div className="column-content budgets">
									{budgets.map((invoice) => {
										/* const formattedDate = new Date(invoice.fecha);
										const dateDay = formattedDate.getDate();
										const dateMonth = formattedDate.getMonth() + 1;
										const dateYear = formattedDate.getFullYear(); */
										return (
											<ReportContent
												key={invoice.idPresupuesto}
												document={invoice}
												title={`Num.${invoice.idPresupuesto}`}
												date={`${invoice.fecha}`}
												price={`${invoice.importeTotal}`}
												buttonLabel="Descargar"
												buttonAction={()=> onPdfLinkClick(invoice, true)}
                        viewAction={() => onPdfLinkClick(invoice, false)}
												type={"Presupuesto"}
											/>
										);
									})}
								</div>
							) : (
								<div>No hay presupuestos disponibles</div>
							)}
						</div>

						{/* Columna de presupuestos  */}

						<div className="right-column">
							<div className="column-top">
								<img src={budgetIcon} alt="icono facturas" />
								<h3>Facturas</h3>
							</div>
							{invoices.length > 0 ? (
								<div className="column-content">
									{invoices.map((invoice) => {
										if (state.userInvoices.filter((invoice) => invoice.numeroFactura).length <= 0) {
										}
										/* const formattedDate = new Date(invoice.fecha);
										const dateDay = formattedDate.getDate();
										const dateMonth = formattedDate.getMonth() + 1;
										const dateYear = formattedDate.getFullYear(); */

										return (
											<ReportContent
												key={invoice.numeroFactura}
												document={invoice}
												title={`Num.${invoice.numeroFactura}`}
												date={`${invoice.fecha}`}
												price={`${invoice.importe}`}
												id={`Serie ${invoice.serie}`}
												buttonLabel="Descargar"
												buttonAction={()=> onPdfLinkClick(invoice, true)}
                        viewAction={() => onPdfLinkClick(invoice, false)}
												type={"Factura"}
											/>
										);
									})}{" "}
								</div>
							) : (
								<div>No hay facturas disponibles</div>
							)}
						</div>
					</div>
				)}
			</React.Fragment>
		);
	};

	/**
	 * @description Renderizado de errores
	 */

	const renderErrors = () => {
		return errorMessage || errorStatus ? (
			<ErrorHandler errorMessage={errorMessage} errorStatus={errorStatus} />
		) : null;
	};

	/***
	 *
	 * @return {Component}
	 * @description Renderizado del componente
	 *
	 */

	return (
		<div className="invoices-container">
			<div>
				<div className="controls">
					<ButtonBack />
					{!loading && <Updating isRefreshing={isRefreshing} />}
				</div>
				{loading ? <Loading /> : null}
				{errorMessage || errorStatus
					? renderErrors()
					: hasElements()
					? renderElements()
					: renderEmpty()}
			</div>
		</div>
	);
};

export default Invoices;
