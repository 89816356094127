//Action Types
export const LOGGED_IN = `auth/LOGGED_IN`;
export const SESSION = "auth/SESSION";
export const LOGGED_OUT = `auth/LOGGED_OUT`;
export const SET_USER_DATA = "auth/SET_USER_DATA";
export const UPDATE_USER_DATA = "auth/UPDATE_USER_DATA";
export const SET_USER_INVOICES = "auth/SET_USER_INVOICES";
export const SET_USER_REPORTS = "auth/SET_USER_REPORTS";
export const SET_IS_LOADING = "auth/SET_IS_LOADING";
export const SET_USER_REPORT_TYPES = "auth/SET_USER_REPORT_TYPES";
export const SET_USER_APPOINMENTS = "auth/SET_USER_APPOINMENTS";
export const SET_USER_MEDICATION = "auth/SET_USER_MEDICATION";
export const SET_USER_NOTES = "auth/SET_USER_NOTES";
export const SET_ERROR = "auth/SET_ERROR";
export const CLEAR_ERROR = "auth/CLEAR_ERROR";
export const HAS_SATISFACTION_POLL = "auth/HAS_SATISFACTION_POLL";
export const SET_LAST_LOCATION = "auth/SET_LAST_LOCATION"; 
export const IS_NEAR_BOTTOM = "auth/IS_NEAR_BOTTOM";
export const IS_NOT_NEAR_BOTTOM = "auth/IS_NOT_NEAR_BOTTOM";


export const initialState = {
  isLoggedIn: false,
  token: null,
  user: null,
  isNearBottom: false
};

//REDUCER
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN: {
      let { user, token, isLoggedIn } = action;
      return { ...state, isLoggedIn, user, token };
    }

    case SESSION: {
      let { user, token, isLoggedIn, userToken, lastLocation} = action;
      return { ...state, isLoggedIn, user, token, userToken, lastLocation };
    }

    case LOGGED_OUT: {
      return { ...initialState };
    }

    case SET_USER_DATA: {
      let { userData } = action;
      return { ...state, userData };
    }

    case UPDATE_USER_DATA: {
      let { email, telefono } = action;
      return { ...state, userData: { ...state.userData, telefono, email } };
    }

    case SET_USER_INVOICES: {
      let { userInvoices } = action;
      return { ...state, userInvoices };
    }

    case SET_USER_REPORTS: {
      let { userReports } = action;
      return { ...state, userReports };
    }

    case SET_USER_MEDICATION: {
      let { userMedication } = action;
      return { ...state, userMedication };
    }

    case SET_USER_NOTES: {
      let { userNotes } = action;
      return { ...state, userNotes };
    }

    case SET_USER_REPORT_TYPES: {
      let { userReportTypes } = action;
      return { ...state, userReportTypes };
    }

    case SET_USER_APPOINMENTS: {
      let { userAppoinments } = action;
      return { ...state, userAppoinments };
    }

    case SET_IS_LOADING: {
      let { globalLoading } = action;
      return { ...state, globalLoading };
    }

    case SET_ERROR: {
      let { globalError } = action;
      return { ...state, globalError };
    }

    case CLEAR_ERROR: {
      return { ...state, globalError: null };
    }

    case HAS_SATISFACTION_POLL: {
      let { satisfactionPoll } = action;
      return { ...state, satisfactionPoll };
    }

    case SET_LAST_LOCATION: {
      let {lastLocation} = action; 
      return {...state, lastLocation}
    } 

    case IS_NEAR_BOTTOM: {
      return {...state, isNearBottom: true}
    }

    case IS_NOT_NEAR_BOTTOM: {
      return {...state, isNearBottom: false}
    }

    default:
      return state;
  }
};

export default authReducer;
